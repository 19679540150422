<template>
  <div class="admin-class">
    <div class="login-class" v-show="!b">
      <vue-qr
        :text="qrdata.url"
        :margin="50"
        colorDark="#409EFF"
        colorLight="#fff"
        :logoScale="0.3"
        :size="200"
      ></vue-qr>
      <el-form ref="form" :model="form" label-width="80px" size="mini">
        <el-form-item label="用户名">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password" type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login()">登录</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table-class" v-if="b">
      <!-- <el-button
        type="primary"
        class="outbutton"
        size="mini"
        @click="exportExcel"
        >导出</el-button
      > -->
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100%"
        class="table-data-class"
      >
        <el-table-column prop="sname" label="姓名" sortable> </el-table-column>
        <el-table-column prop="ministry" label="学部(院)" sortable>
        </el-table-column>
        <el-table-column prop="dorm" label="宿舍号" sortable> </el-table-column>
        <el-table-column prop="sphonenum" label="联系方式"> </el-table-column>
        <el-table-column prop="fname1" label="来访人1姓名"> </el-table-column>
        <!-- <el-table-column prop="fidcard1" label="来访人1身份证号">
        </el-table-column> -->
        <el-table-column prop="fphone1" label="来访人1联系方式">
        </el-table-column>
        <el-table-column prop="fcarnum1" label="来访人1车牌号">
        </el-table-column>
        <el-table-column prop="fname2" label="来访人2姓名"> </el-table-column>
        <!-- <el-table-column prop="fidcard2" label="来访人2身份证号">
        </el-table-column> -->
        <el-table-column prop="fphone2" label="来访人2联系方式">
        </el-table-column>
        <el-table-column prop="fcarnum2" label="来访人2车牌号" sortable>
        </el-table-column>
        <el-table-column prop="jcs" label="接触史"> </el-table-column>
        <el-table-column prop="mjs" label="密接史"> </el-table-column>
        <el-table-column prop="zz" label="症状"> </el-table-column>
        <el-table-column prop="jcq" label="观察/监测期"> </el-table-column>
        <el-table-column prop="lm" label="绿码"> </el-table-column>
        <el-table-column prop="ym" label="疫苗"> </el-table-column>
        <el-table-column prop="day" label="日期" sortable> </el-table-column>
        <el-table-column prop="time" label="时间段" sortable> </el-table-column>
        <el-table-column prop="yym" label="预约码" sortable> </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <!-- <el-button size="mini" @click="upset(scope.$index, scope.row, 1)"
              >通过</el-button
            > -->
            <div
              :style="{
                color:
                  scope.row.state == -1
                    ? '#E6A23C'
                    : scope.row.state == 0
                    ? '#F56C6C'
                    : '#67C23A',
              }"
            >
              {{
                scope.row.state == -1
                  ? "审核中"
                  : scope.row.state == 0
                  ? "未通过"
                  : "通过"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-popconfirm
              title="您确定通过此申请吗？"
              @confirm="upset(scope.$index, scope.row, 1)"
              v-show="scope.row.state != 0"
            >
              <el-button size="mini" slot="reference">通过</el-button>
            </el-popconfirm>

            <el-popconfirm
              title="您确定不通过此申请吗？"
              @confirm="upset(scope.$index, scope.row, 0)"
              v-show="scope.row.state != 0"
            >
              <el-button size="mini" type="danger" slot="reference"
                >不通过</el-button
              >
            </el-popconfirm>
            <div v-show="scope.row.state == 0">未通过，需重新提交</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
// import FileSaver from "file-saver";
// import XLSX from "xlsx";
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      b: false,
      form: {},
      tableData: [],
      qrdata: {
        url:
          window.location.href.split("/#/")[0] + "/#/administratormanagement",
      },
    };
  },
  mounted() {},
  methods: {
    login() {
      let formdata = new FormData();
      for (let i in this.form) {
        formdata.append(i, this.form[i]);
      }
      this.$axios.post("/carapi/login.php", formdata).then((res) => {
        if (res.data.message == "success") {
          this.b = true;
          sessionStorage.setItem("session", res.data.data);
          this.$axios
            .get(
              "/carapi/getalldata.php?s=" + sessionStorage.getItem("session")
            )
            .then((res) => {
              this.tableData = res.data;
            });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    upset(index, row, n) {
      let formdata = new FormData();
      // for (let i in this.form) {
      //   formdata.append(i, this.form[i]);
      // }
      formdata.append("id", row.Id);
      formdata.append("state", n);
      formdata.append("s", sessionStorage.getItem("session"));

      this.$axios.post("/carapi/setstate.php", formdata).then((res) => {
        if (res.data.message == "success") {
          this.$axios
            .get(
              "/carapi/getalldata.php?s=" + sessionStorage.getItem("session")
            )
            .then((res) => {
              this.tableData = res.data;
            });
        } else {
          this.$message.error(res.data.message);
          this.b = false;
        }
      });
    },
    // 导出
    // exportExcel() {
    //   // var wb = XLSX.utils.table_to_book(
    //   //   document.querySelector(".table-data-class")
    //   // );
    //   // var wbout = XLSX.write(wb, {
    //   //   bookType: "xlsx",
    //   //   bookSST: true,
    //   //   type: "array",
    //   // });
    //   // try {
    //   //   FileSaver.saveAs(
    //   //     new Blob([wbout], { type: "application/octet-stream" }),
    //   //     "导出数据.xlsx"
    //   //   );
    //   // } catch (e) {
    //   //   if (typeof console !== "undefined") console.log(e, wbout);
    //   // }
    //   // return wbout;
    //   let outtable = [];
    //   for (let i in this.tableData) {
    //     outtable.push([]);
    //     for (let j in this.tableData[i]) {
    //       outtable[i].push(this.tableData[i][j]);
    //     }
    //   }
    //   let worksheet = XLSX.utils.aoa_to_sheet(outtable);
    //   let workbook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(workbook, worksheet, "数据");
    //   XLSX.writeFile(workbook, "导出数据.xlsx");
    // },
  },
};
</script>

<style lang="scss" scoped>
.admin-class {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .login-class {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .table-class {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .outbutton {
      position: fixed;
      right: 10px;
      top: 10px;
      z-index: 5;
    }
  }
}
</style>